import '@module/WhiteLabel/forex.scss';
import '@module/WhiteLabel/modern.scss';

import { documentReady, urlParameterDelete } from '@utils/toolbox';

documentReady(() => {
	// Remove query parameter from URL to avoid duplicate entries when user navigates
	const queryParameter = js_params?.module?.WhiteLabel?.queryParameter || 'partner';
	urlParameterDelete(queryParameter);
});
